import ApiProvider from "./ApiProvider";

async function wait(time: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
}

export default class ReportProvider {
	private _api_provider: ApiProvider;

	constructor(api_provider: ApiProvider) {
		this._api_provider = api_provider;
	}

	async getReport(
		report: IReport,
		refresh?: boolean,
		cancelled?: () => boolean,
		format?: string
	): Promise<any> {
		let job_result: {
			job_id: string;
		} = await this._api_provider.post("job/initiate", {
			reports: [report],
			refresh: refresh,
		});

		let done = false;

		while (!done) {
			if (cancelled && cancelled()) {
				return {
					success: false,
					error: "Cancelled",
				};
			}

			let job_progress: {
				progress: number;
			} = await this._api_provider.post("job/progress", {
				job_id: job_result.job_id,
			});

			if (job_progress.progress == 1) {
				done = true;
			} else {
				await wait(2000);
			}
		}

		if (format && format === 'excel') {
			return this._api_provider.post("sheet/raw", {
				job_id: job_result.job_id,
			});
		}

		return this._api_provider.post("report/get", {
			job_id: job_result.job_id,
		});
	}
}
