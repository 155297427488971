import axios from "axios";
import querystring, { StringifiableRecord } from "query-string";

export default class ApiProvider {
	private readonly _endpoint: string;
	private _auth_token_generator?: IAuthTokenGenerator;

	constructor(endpoint: string, auth_token_generator?: IAuthTokenGenerator) {
		this._endpoint = endpoint;
		this._auth_token_generator = auth_token_generator;
	}

	async setAuthTokenGenerator(auth_token_generator?: IAuthTokenGenerator) {
		this._auth_token_generator = auth_token_generator;
	}

	async get(
		method: string,
		query_params?: StringifiableRecord | null,
		body_params?: object | null
	): Promise<any> {
		return this.execute(method, "GET", query_params, body_params);
	}

	async post(
		method: string,
		body_params?: object | null,
		query_params?: StringifiableRecord | null
	): Promise<any> {
		return this.execute(method, "POST", query_params, body_params);
	}

	async put(
		method: string,
		body_params?: object | null,
		query_params?: StringifiableRecord | null
	): Promise<any> {
		return this.execute(method, "PUT", query_params, body_params);
	}

	protected async execute(
		method: string,
		http_method: "POST" | "GET" | "PUT",
		query_params?: StringifiableRecord | null,
		body_params?: object | null
	): Promise<any> {
		const headers: { [field: string]: string } = {
			"Content-Type": "application/json",
		};

		if (this._auth_token_generator) {
			headers[
				"Authorization"
			] = `Bearer ${this._auth_token_generator.getAuthToken()}`;
		}

		try {
			let url;

			if (query_params) {
				url = `${this._endpoint}/${method}?${querystring.stringify(
					query_params
				)}`;
			} else {
				url = `${this._endpoint}/${method}`;
			}

			let result = await axios({
				headers: http_method === "POST" && headers,
				method: http_method,
				url: url,
				data: body_params,
				withCredentials: false,
			});

			return result.data;
		} catch (error: any) {
			if (error.response) {
				if (error.response.data && error.response.data.error) {
					throw error.response.data.error;
				} else {
					throw error.response.data;
				}
			} else {
				throw error;
			}
		}
	}
}
